import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <h1>Page Not Found</h1>
    <p>
      Your journey to more light has gotten a bit dimmer, as you hit a route
      that doesn't exist.
    </p>
    <ul className="actions">
      <li>
        <Link to="/" className="button">
          Return Home
        </Link>
      </li>
    </ul>
  </Layout>
)

export default NotFoundPage

import { Link } from 'gatsby'
import React from 'react'

const Footer = (props) => (
  <footer id="footer">
    <section>
      <h2>Interested in Membership</h2>
      <p>
        Membership in the Scottish Rite is open to all 3° Masons who have
        completed their Master Mason's Catechism.
      </p>
      <ul className="actions">
        <li>
          <Link to="/contact" className="button">
            Contact Us
          </Link>
        </li>
      </ul>
    </section>
    <section>
      <h2>Contact Us</h2>
      <dl className="alt">
        <dt>Address</dt>
        <dd>6816 Blentlinger Rd. Suite A &bull; Frederick, MD 21702</dd>
        <dt>Email</dt>
        <dd>
          <a href="mailto:frederickscottishrite@gmail.com">
            frederickscottishrite@gmail.com
          </a>
        </dd>
      </dl>
      <ul className="icons">
        <li>
          <a
            href="https://twitter.com/thescottishrite"
            className="icon fa-twitter alt"
          >
            <span className="label">Twitter</span>
          </a>
        </li>
        <li>
          <a
            href="https://facebook.com/FrederickKSA"
            className="icon fa-facebook alt"
          >
            <span className="label">Facebook</span>
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/channel/UCv-F13FNBaW-buecl7p8cJg"
            className="icon fa-youtube alt"
          >
            <span className="label">YouTube</span>
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/scottishritesj/"
            className="icon fa-instagram alt"
          >
            <span className="label">Instagram</span>
          </a>
        </li>
        <li>
          <a
            href="https://reddit.com/r/scottishrite"
            className="icon fa-reddit alt"
          >
            <span className="label">Reddit</span>
          </a>
        </li>
      </ul>
    </section>
    <p className="copyright">
      &copy; Valley of Frederick, A&amp;ASR. Design:{' '}
      <a href="https://html5up.net">HTML5 UP</a>.
    </p>
  </footer>
)

export default Footer
